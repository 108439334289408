import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const NFPCountDown = ({ targetDate }) => {
  const language = useSelector((state) => state.language);

  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return (
    <div className="countdown-main">
      <div className="countdown-time">
        <span className="countdown-content">{timeRemaining.days}</span>
        <span className="countdown-head">Days</span>
      </div>
      <span className="countdown-col">:</span>
      <div className="countdown-time">
        <span className="countdown-content">{timeRemaining.hours}</span>
        <span className="countdown-head">Hours</span>
      </div>
      <span className="countdown-col">:</span>
      <div className="countdown-time">
        <span className="countdown-content">{timeRemaining.minutes}</span>
        <span className="countdown-head">Minutes</span>
      </div>
      <span className="countdown-col">:</span>
      <div className="countdown-time">
        <span className="countdown-content">{timeRemaining.seconds}</span>
        <span className="countdown-head">Seconds</span>
      </div>
    </div>
  );
};

export default NFPCountDown;
